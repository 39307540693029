<script setup lang="ts">
const dayjs = useDayjs();
const router = useRouter();
const props = defineProps<{
  title: string;
  text: string;
  hash: string;
  date: string;
  baseMessage?: any;
}>();
const onNotyClick = () => {
  if (props.baseMessage?.orderId) {
    if (props.baseMessage?.moderator) {
      router.push(`/administration/disputes/${props.baseMessage?.orderId}`);
    } else {
      router.push(`/trade/orders/${props.baseMessage?.orderId}`);
    }
  }
};
</script>

<template>
  <div class="p-2 notification-item rd" @click="onNotyClick">
    <h4 class="font-size-3 mb-0 flex flex-justify-between">
      {{ props.title }}
      <span class="time font-size-2 opacity-55">{{
        dayjs(props.date).fromNow()
      }}</span>
    </h4>
    <p class="mb-0">{{ props.text }}</p>
  </div>
</template>

<style scoped lang="scss">
.notification-item {
  min-width: 200px;
  cursor: pointer;

  &:hover {
    background-color: var(--el-border-color-extra-light);
  }

  .time {
    font-weight: 400;
  }
}
</style>
